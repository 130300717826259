import styled from 'styled-components'
import { screenSizes } from '@shipt/design-system-themes'
import { AddressAutocomplete } from '@/features/shared/components/Autocomplete/AddressAutocomplete'
import { Body } from '@shipt/design-system-typography'
import { routes } from '@shared/constants/routes'
import { useRouter } from 'next/router'
import { type ValidAddress } from '@/features/account/services/Addresses/types'
import { useEffect, useState } from 'react'
import { useHandleDialog } from '@/features/shared/state/Dialog/useHandleDialog'
import { useFetchShoppingStores } from '@/features/shop/services/ShoppingStore/queries'
import { isValidAddress } from '@/features/account/components/AddressForm/validations'
import { type ShoppingStoreDetails } from '@/features/shop/services/ShoppingStore/types'
import { Button } from '@shipt/design-system-buttons'
import { toSnakeCase } from '@/features/shared/utils/formatter'
import { TextButtonLink } from '@/features/shared/elements/Link/Buttons'
import { useGuestUserContext } from '@/features/shared/context/GuestUserContext'
import { VisuallyHidden } from '@/features/shared/elements/VisuallyHidden'
import { trackElementClickedForAddedAddress } from '@/features/shared/analytics/address'
import { useMutation } from '@tanstack/react-query'
import { type AddressCheckData } from '@/features/cms/components/CMS/types'
import { useCMSTrackingEvents } from '@/features/cms/components/CMS/CMSContext'
import { DynamicZipNotCoveredModal } from '@/features/account/components/Modal/ZipNotCoveredModal/DynamicZipNotCoveredModal'
import { useUser } from '@/features/account/services/User/hooks'

/**
 * This component corresponds to the `address_check` content type in the CMS.
 *
 * - **Staging** - https://cms-staging.shipt.com/content-type/pool/address_check
 * - **Production** - https://cms.shipt.com/content-type/pool/address_check
 */
export const AddressCheck = ({ data }: AddressCheckData) => {
  const { button_callout, button_treatment, surface = 'default' } = data
  const { openDialog } = useHandleDialog()
  const [address, setAddress] = useState<ValidAddress>()
  const [errorMessage, setErrorMessage] = useState('')
  const router = useRouter()
  const { mutateAsync: setGuestAddress, isPending } = useMutation({
    mutationFn: useGuestUserContext()?.setGuestAddressCookie,
  })
  const { trackCMSElementViewed, trackCMSElementClicked } =
    useCMSTrackingEvents()

  useEffect(() => {
    trackCMSElementViewed({ content: 'address check input' })
  }, [trackCMSElementViewed])

  const handleDraftAddress = (draftAddress: ValidAddress) => {
    if (isValidAddress(draftAddress)) {
      setAddress(draftAddress)
      setErrorMessage('')
    }
  }

  const enteredZip = address?.zip_code
  const { fetchStores, isLoading: isLoadingStores } = useFetchShoppingStores()
  const isLoading = isLoadingStores || isPending
  const user = useUser()

  const handleGuestUserAddress = async (validAddress: ValidAddress) => {
    trackElementClickedForAddedAddress()
    if (!user) {
      // Set the guest address cookie if the user is not authenticated
      await setGuestAddress(validAddress)
    }
    // Navigate to GHP
    router.push(routes.GLOBAL_HOMEPAGE.url)
  }

  const handleZipCode = async () => {
    const checkStores = (stores: ShoppingStoreDetails[]) => {
      if (stores.length && address) {
        handleGuestUserAddress(address)
      } else {
        if (!enteredZip) return
        openDialog(DynamicZipNotCoveredModal, { zip: enteredZip })
        setAddress(undefined)
        setErrorMessage('Zip code address not in service area')
      }
    }
    if (address) {
      try {
        const stores = await fetchStores({ address })
        setErrorMessage('')
        checkStores(stores.delivery_stores)
      } catch (error) {
        checkStores([])
      }
    }
  }
  const handleGetStartedClick = () => {
    if (!address) {
      setErrorMessage('Please enter and select a valid address.')
    } else {
      handleZipCode()
      trackCMSElementClicked({
        type: 'button',
        content: toSnakeCase(button_callout),
        display_sublocation: 'hero',
      })
    }
  }

  return (
    <AddressWrapper>
      <AddressInputWrapper className="address-input-wrapper">
        <HomepageAddressInput
          id="address-autocomplete-homepage"
          onSubmit={handleDraftAddress}
        />
        <GetStartedButton
          loading={isLoading}
          disabled={isLoading}
          concept={button_treatment}
          surface={surface}
          onClick={handleGetStartedClick}
          aria-label={`${button_callout} - address input`}
        >
          {button_callout}
        </GetStartedButton>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </AddressInputWrapper>
      <NoAddressHeading variant="secondary" surface={surface}>
        Don't see your address?{' '}
        <AddManualButtonLink
          size="md"
          surface={surface}
          concept={button_treatment}
          href={routes.GUEST_WELCOME.url}
        >
          Add<VisuallyHidden>address</VisuallyHidden>manually
        </AddManualButtonLink>
      </NoAddressHeading>
    </AddressWrapper>
  )
}

const ErrorMessage = styled(Body)`
  color: ${({ theme }) => theme.red500};
`

export const AddressWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: inherit;
`

const AddressInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.75rem;

  @media ${screenSizes.tablet} {
    padding-right: 0;
  }
`

const HomepageAddressInput = styled(AddressAutocomplete)`
  max-width: 20rem;
  flex-grow: 1;
  flex-shrink: 0;

  @media ${screenSizes.max_mobile} {
    margin: 0 auto;
  }
`

const GetStartedButton = styled(Button)`
  display: flex;
  max-width: 20rem;
  flex-grow: 1;
  flex-shrink: 0;

  @media ${screenSizes.max_mobile} {
    width: 100%;
    margin: 0 auto;
  }
`

const NoAddressHeading = styled(Body)`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 0;

  @media ${screenSizes.max_mobile} {
    justify-content: center;
  }
`

const AddManualButtonLink = styled(TextButtonLink)`
  gap: 0.25rem;
  margin-left: 0.5rem;
`
