import { states } from '@/features/shared/constants/states'
import { isValidZip } from '@/features/shared/utils/validator'
import { type ValidAddress } from '@/features/account/services/Addresses/types'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

export const zipValidation = z.string().refine(isValidZip, {
  message: 'Zip is invalid',
})

const isValidStateAbbreviation = (state = '') => {
  return state.toUpperCase() in states
}

const stateValidation = z.string().refine(isValidStateAbbreviation, {
  message: 'State is invalid',
})

const addressValidationSchema = z.object({
  street1: z.string().min(1, 'Street must be present'),
  street2: z.string().optional(),
  city: z.string().min(1, 'City must be present'),
  state: stateValidation,
  zip_code: zipValidation,
  delivery_instructions: z.string().optional(),
  door_dropoff: z.boolean().optional(),
})

export const useAddressForm = () => {
  const {
    handleSubmit,
    control,
    resetField,
    setError,
    reset,
    formState: { errors },
  } = useForm<ValidAddress>({
    resolver: zodResolver(addressValidationSchema),
  })

  return {
    handleSubmit,
    control,
    resetField,
    setError,
    reset,
    formState: { errors },
  }
}

export const isValidAddress = (
  address?: ValidAddress | null
): address is ValidAddress => {
  const { street1, city, state, zip_code } = address ?? {}
  return (
    Boolean(street1?.length) &&
    Boolean(city?.length) &&
    isValidStateAbbreviation(state) &&
    isValidZip(zip_code ?? '')
  )
}
